<template>
  <div class="main">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-position="top"
      label-width="100px"
    >
      <AssociationScroll :showTabs="false">
        <AssociationScrollView title="基本信息" name="0">
          <div class="base-container">
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.insureProgrammeName"
              label="承保方案名称"
            />
            <el-form-item
              v-else
              label="承保方案名称"
              prop="insureProgrammeName"
              required
            >
              <el-input
                clearable
                v-model="formData.insureProgrammeName"
              ></el-input>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.category"
              :dic="typesInsuranceList"
              label="险种类型"
            />
            <el-form-item v-else label="险种类型" prop="category" required>
              <el-select
                clearable
                @change="typesInsuranceChange"
                filterable
                v-model="formData.category"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in typesInsuranceList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                ></el-option>
              </el-select>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.vehicleTypeName"
              :dic="dict[devTypeMap[category]]"
              label="设备类型"
            />
            <el-form-item
              v-if="category != 3 && actionType != 'view'"
              label="设备类型"
              prop="vehicleTypeValue"
              required
            >
              <el-select
                clearable
                filterable multiple
                v-model="formData.vehicleTypeValue"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dict[devTypeMap[category]]"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey + ''"
                ></el-option>
              </el-select>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.companyValue"
              :dic="dict.insuranceCompany"
              label="保险公司"
            />
            <el-form-item v-else label="保险公司" prop="companyValue" required>
              <el-select
                clearable
                filterable
                @change="insuranceCompanyChange"
                v-model="formData.companyValue"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dict.insuranceCompany"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                ></el-option>
              </el-select>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.branch"
              :dic="insuranceBranchList"
              label="分支机构"
            />
            <el-form-item v-else label="分支机构">
              <el-select
                clearable
                filterable
                v-model="formData.branch"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in insuranceBranchList"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                ></el-option>
              </el-select>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.categoryValue"
              :dic="dict.category_name"
              label="所属部门"
            />
            <el-form-item v-else label="所属部门" prop="categoryValue" required>
              <el-select
                clearable
                filterable
                v-model="formData.categoryValue"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dict.category_name"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                ></el-option>
              </el-select>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.viewDeptName"
              label="查看部门"
            />
            <el-form-item v-else label="查看部门" prop="viewDeptId" required>
              <el-select
                v-model="formData.viewDeptName"
                filterable
                remotepopper-class="staff-demo-ruleForm"
                placeholder="请选择查看部门"
                remote
                clearable
                @visible-change="visibleChange"
                :remote-method="remoteMethod"
              >
                <el-option label value>
                  <div @click.stop="">
                    <el-tree
                      :data="deptOptions"
                      check-strictly
                      show-checkbox
                      ref="deptTree"
                      default-expand-all
                      node-key="id"
                      :props="defaultProps"
                      @check-change="changeDeptId"
                      :filter-node-method="filterNode"
                    ></el-tree>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.interestBegin"
              label="起期"
            />
            <el-form-item v-else label="起期" prop="interestBegin" required>
              <el-date-picker
                v-model="formData.interestBegin"
                type="date"
                format="yyyy / MM / dd"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="userInfo.name"
              label="录单员"
            />
            <el-form-item v-else label="录单员" required>
              <el-input v-model="userInfo.name" disabled></el-input>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.insureProgrammeIllustrate"
              label="产品方案说明"
            />
            <el-form-item v-else label="产品方案说明">
              <el-input
                clearable
                v-model="formData.insureProgrammeIllustrate"
              ></el-input>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.isAgentSideDisplay"
              isRadio
              label="是否代理人端显示"
            />
            <el-form-item
              v-else
              label="是否代理人端显示"
              prop="isAgentSideDisplay"
              required
            >
              <el-radio-group v-model="formData.isAgentSideDisplay">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.isClientDisplay"
              isRadio
              label="是否客户端显示"
            />
            <el-form-item
              v-else
              label="是否客户端显示"
              prop="isClientDisplay"
              required
            >
              <el-radio-group v-model="formData.isClientDisplay">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.clientDisplayName"
              label="客户端显示名称"
            />
            <el-form-item
              v-else
              label="客户端显示名称" :prop="formData.isClientDisplay ? 'clientDisplayName' : 'clientDisplayName1'"
            >
              <el-input
                clearable
                v-model="formData.clientDisplayName"
              ></el-input>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.vehicleTypeId"
              :dic="dict.equipmentType"
              label="小程序设备类型"
            />
            <el-form-item
              v-else
              label="小程序设备类型"
              prop="vehicleTypeId"
              required
            >
              <el-select
                clearable
                filterable
                v-model="formData.vehicleTypeId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dict.equipmentType"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                ></el-option>
              </el-select>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.attachmentResp"
              isImg
              label="缩略图"
            />
            <el-form-item v-else label="缩略图" :prop="formData.isClientDisplay ? 'attachmentResp' : 'attachmentResp1'">
              <ImageUpload
                v-model="formData.attachmentResp"
                :type="'SLT'"
              ></ImageUpload>
            </el-form-item>
            <!-- <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.insuranceTermsResp"
              isFile
              label="保险条款"
            />
            <el-form-item v-else label="保险条款" prop="BXTK">
              <ImageUpload
                v-model="formData.insuranceTermsResp"
                :type="'BXTK'"
              ></ImageUpload>
            </el-form-item> -->
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.insuranceTerms"
              isRich
              label="保险条款"
            />
            <el-form-item
              v-else
              label="保险条款"
              prop="insuranceTerms"
            >
              <div class="vue-quill-editor">
                <quill-editor
                  ref="mwQuillEditor"
                  v-model="formData.insuranceTerms"
                  :options="editorConfig"
                />
              </div>
            </el-form-item>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="保障计划" name="1">
          <ConfigurationSelectionMain
            ref="configFrom"
            width="100%"
            :model="formData"
          >
            <ConfigurationSelection
              title="主险"
              :disabled="actionType == 'view'"
              v-for="(maIns, mi) in formData.mainInsuranceList"
              :key="`mi${mi}`"
              :typeList="dict.mainInsuranceDict"
              :defaultProps="{
                dictKey: 'id',
                dictValue: 'termConfigurationName',
              }"
              v-model="maIns.id"
              @select="selectTemplate($event, 'mainInsuranceList', mi)"
              :prop="`mainInsuranceList.${mi}.insPolicyInsuranceTypeAppendRespList`"
              width="100%"
            >
              <div>
                <el-table
                  :data="maIns.itemconformity"
                  border
                  style="width: 100%"
                >
                  <el-table-column type="index" label="序号" width="50" />
                  <el-table-column
                    prop="optionName"
                    label="险种（条款）名称"
                    width="180"
                  >
                    <div>
                      {{ maIns.termConfigurationName }}
                    </div>
                  </el-table-column>
                  <el-table-column
                    prop="insuranceAmount"
                    label="保额/限额"
                    width="180"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="compensationAmount"
                    label="每次赔偿限额"
                  >
                  </el-table-column>
                  <el-table-column prop="rate" label="年费率/金额">
                    <div slot-scope="{ row }">
                      {{ row.rate }}
                    </div>
                  </el-table-column>
                  <el-table-column label="保费">
                    <div>浮动计算</div>
                  </el-table-column>
                  <el-table-column prop="address" label="操作">
                    <div slot-scope="{ row }">
                      <el-checkbox
                        :disabled="actionType == 'view'"
                        v-model="row.isMandatory"
                        >必填</el-checkbox
                      >
                      <el-checkbox
                        :disabled="actionType == 'view'"
                        v-model="row.isFixedSelection"
                        >固定选择</el-checkbox
                      >
                    </div>
                  </el-table-column>
                </el-table>
              </div>
            </ConfigurationSelection>
            <ConfigurationSelection
              v-for="(addIns, aii) in formData.additionalInsuranceList"
              :key="aii"
              title="附加险"
              :disabled="actionType == 'view'"
              :typeList="dict.additionalInsuranceDict"
              :defaultProps="{
                dictKey: 'id',
                dictValue: 'termConfigurationName',
              }"
              @select="selectTemplate($event, 'additionalInsuranceList', aii)"
              v-model="addIns.id"
              width="100%"
              :prop="`additionalInsuranceList.${aii}.insPolicyInsuranceTypeAppendRespList`"
              selectClose
            >
              <div
                slot="selectright"
                v-if="actionType != 'view'"
                style="margin-left: 16px"
              >
                <el-button
                  v-if="aii === 0"
                  @click="additionalInsuranceAdd"
                  type="success"
                  icon="el-icon-circle-plus-outline"
                  >增加附加险</el-button
                >
                <el-button
                  v-else
                  @click="deletAdditionalInsurance(aii)"
                  type="danger"
                  icon="el-icon-delete"
                  >删除</el-button
                >
              </div>
              <div>
                <el-table
                  :data="addIns.itemconformity"
                  border
                  style="width: 100%"
                >
                  <el-table-column type="index" label="序号" width="50" />
                  <el-table-column
                    prop="optionName"
                    label="险种（条款）名称"
                    width="180"
                  >
                    <div>
                      {{ addIns.termConfigurationName }}
                    </div>
                  </el-table-column>
                  <el-table-column
                    prop="insuranceAmount"
                    label="保额/限额"
                    width="180"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="compensationAmount"
                    label="每次赔偿限额"
                  >
                  </el-table-column>
                  <el-table-column prop="rate" label="年费率/金额">
                    <div slot-scope="{ row }">
                      {{ row.rate }}
                    </div>
                  </el-table-column>
                  <el-table-column label="保费">
                    <div>浮动计算</div>
                  </el-table-column>
                  <el-table-column label="操作">
                    <div slot-scope="{ row }">
                      <el-checkbox
                        :disabled="actionType == 'view'"
                        v-model="row.isMandatory"
                        >必填</el-checkbox
                      >
                      <el-checkbox
                        :disabled="actionType == 'view'"
                        v-if="addIns.termConfigurationType === 0"
                        v-model="row.isFixedSelection"
                        >固定选择</el-checkbox
                      >
                    </div>
                  </el-table-column>
                </el-table>
              </div>
            </ConfigurationSelection>
            <ConfigurationSelection
              title="免费扩展条款"
              :disabled="actionType == 'view'"
              :typeList="dict.freeExtensionDict"
              :defaultProps="{
                dictKey: 'id',
                dictValue: 'termConfigurationName',
              }"
              @select="selectTemplate($event, 'freeExtensionIllustrate')"
              v-model="formData.freeXtensionErmsId"
              prop="freeXtensionErmsId"
            >
              <div slot="selectright">
                <el-checkbox
                  :disabled="actionType == 'view'"
                  v-model="formData.isFreeXtensionErms"
                  >客户端显示说明内容</el-checkbox
                >
              </div>
              <div>
                <el-input :disabled="actionType == 'view'" @input="$forceUpdate()"
                  v-model="templateData.freeExtensionIllustrate"
                  type="textarea"
                  :rows="5"
                  placeholder="请输入内容"
                >
                </el-input>
              </div>
            </ConfigurationSelection>
            <ConfigurationSelection
              title="特别约定"
              :disabled="actionType == 'view'"
              :typeList="dict.specialAgreementDict"
              :defaultProps="{
                dictKey: 'id',
                dictValue: 'termConfigurationName',
              }"
              v-model="formData.specialAgreementId"
              @select="selectTemplate($event, 'specialAgreementIllustrate')"
              prop="specialAgreementId"
            >
              <div slot="selectright">
                <el-checkbox
                  :disabled="actionType == 'view'"
                  v-model="formData.isSpecialAgreement"
                  >客户端显示说明内容</el-checkbox
                >
              </div>
              <div>
                <el-input
                  type="textarea" :disabled="actionType == 'view'" @input="$forceUpdate()"
                  v-model="templateData.specialAgreementIllustrate"
                  :rows="5"
                  placeholder="请输入内容"
                >
                </el-input>
              </div>
            </ConfigurationSelection>
            <ConfigurationSelection
              title="特别说明"
              :disabled="actionType == 'view'"
              :typeList="dict.specialInstructionsDict"
              :defaultProps="{
                dictKey: 'id',
                dictValue: 'termConfigurationName',
              }"
              v-model="formData.specificationId"
              @select="selectTemplate($event, 'specificationIllustrate')"
              prop="specificationId"
            >
              <div slot="selectright">
                <el-checkbox
                  :disabled="actionType == 'view'"
                  v-model="formData.isSpecification"
                  >客户端显示说明内容</el-checkbox
                >
              </div>
              <div>
                <el-input
                  disabled
                  v-model="templateData.specificationIllustrate"
                  type="textarea"
                  :rows="5"
                  placeholder="请输入内容"
                >
                </el-input>
              </div>
            </ConfigurationSelection>
            <ConfigurationSelection
              title="每次事故绝对免赔额"
              :disabled="actionType == 'view'"
              :typeList="dict.absoluteDeductibleDict"
              :defaultProps="{
                dictKey: 'id',
                dictValue: 'termConfigurationName',
              }"
              @select="selectTemplate($event, 'absoluteDeductibleIllustrate')"
              v-model="formData.isExtensionWithoutDeductibleId"
              prop="isExtensionWithoutDeductibleId"
            >
              <CheckboxGroupText
                v-model="formData.deductible" :disabled="actionType == 'view'"
                :checkboxdisabled="actionType == 'view'"
                @select="selectDeductible"
                :list="deductibleList"
                isSingle
              ></CheckboxGroupText>
            </ConfigurationSelection>
          </ConfigurationSelectionMain>
        </AssociationScrollView>
        <AssociationScrollView title="绩效方案" name="2">
          <div class="base-container">
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.agentKick"
              label="代理商返点"
            />
            <el-form-item
              v-else
              label="代理商返点"
              prop="agentKick"
            >
              <el-input
                clearable type="number"
                v-model="formData.agentKick"
              ></el-input>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.brokerage"
              label="佣金绩效费率"
            />
            <el-form-item
              v-else
              label="佣金绩效费率"
              prop="brokerage"
            >
              <el-input
                clearable type="number"
                v-model="formData.brokerage"
              ></el-input>
            </el-form-item>
            <FormItemText
              v-if="actionType == 'view'"
              v-model="formData.otherBrokerage"
              label="其它绩效费率"
            />
            <el-form-item
              v-else
              label="其它绩效费率"
              prop="otherBrokerage"
            >
              <el-input
                clearable type="number"
                v-model="formData.otherBrokerage"
              ></el-input>
            </el-form-item>
          </div>
        </AssociationScrollView>
      </AssociationScroll>
    </el-form>
    <!-- 底部操作栏 -->
    <div class="bottom-btn">
      <div class="btn">
        <el-button @click="backToHome" class="text">返回</el-button>
      </div>
      <div v-if="actionType != 'view'" class="btn">
        <el-button @click="submit" class="save text" icon="el-icon-circle-check"
          >保存</el-button
        >
      </div>
      <div v-else class="btn">
        <el-button
          @click="actionType = 'edit'"
          class="save text"
          icon="el-icon-edit"
          >编辑</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  dictionaryBatch,
  getClientEquipmentTypeList,
  getUserByCharge,
  getInsuranceCompanyClause,
  addInsuranceScheme,
  getInsuranceSchemeDeatil,
} from "@/api/policy";
import { getOrganizationTree } from "@/api/SystemData";
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import ConfigurationSelection from "../components/ConfigurationSelection.vue";
import { uploadFile } from "@/utils/index";
import { OSSDirectPass } from "@/api/oss.js";
import ConfigurationSelectionMain from "../components/ConfigurationSelectionMain.vue";
import CheckboxGroupText from "../components/CheckboxGroupText.vue";
import ImageUpload from "@/components/ImageUpload";
import FormItemText from "../components/FormItemText.vue";
import { Quill } from "vue-quill-editor";
import Video from "@/utils/quill/video.js";
Quill.register(Video, true);
// 设置字体大小
const fontSizeStyle = Quill.import("attributors/style/size"); // 引入这个后会把样式写在style上
fontSizeStyle.whitelist = [
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "28px",
  "32px",
  "36px",
];
Quill.register(fontSizeStyle, true);
export default {
  components: {
    AssociationScroll,
    AssociationScrollView,
    ConfigurationSelection,
    ConfigurationSelectionMain,
    CheckboxGroupText,
    ImageUpload,
    FormItemText,
  },
  data() {
    return {
      category: 1, //路由传参
      actionType: "add", //路由传参 view
      id: "", //路由传参
      // addSelect: '',
      // additionShowAdd: false,
      devTypeMap: {
        1: "vehicleType", //工程机械设备类型
        2: "CXvehicleType", //车险设备类型
      },
      rateUnit: {
        0: "元",
        1: "%",
      },
      dict: {
        equipmentType: [],
        userChargeList: [],
      },
      //查看部门
      deptOptions: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      //
      insuranceBranchList: [],
      typesInsuranceList: [
        {
          dictKey: 1,
          dictValue: "工程机械",
        },
        {
          dictKey: 2,
          dictValue: "车险",
        },
        {
          dictKey: 3,
          dictValue: "非设备类财产险",
        },
      ],
      deductibleList: [
        {
          text: "扩展不计免赔1（0免赔）",
          value: "",
          key: "isExtensionWithoutDeductibleOne",
          kye2: "extensionWithoutDeductibleOne",
        },
        {
          text: "扩展不计免赔2（绝对免赔1000元）",
          value: "",
          key: "isExtensionWithoutDeductibleTwo",
          kye2: "extensionWithoutDeductibleTwo",
        },
        {
          text: "未扩展不计免赔",
          value: "",
          key: "isExtensionWithoutDeductible",
          kye2: "extensionWithoutDeductible",
        },
      ],
      checkList: [],
      formData: {
        //需要处理
        mainInsuranceList: [
          {
            insuranceTypeAppendId: "",
            insPolicyInsuranceTypeAppendRespList: [{ itemconformity: [] }],
          },
        ],
        additionalInsuranceList: [
          {
            insuranceTypeAppendId: "",
            insPolicyInsuranceTypeAppendRespList: [{ itemconformity: [] }],
          },
        ],
        vehicleTypeValue: [],
        deductible: [],
        category: "",
        branch: "",
        specificationId: "",
        specialAgreementId: "",
        freeXtensionErmsId: "",
        freeXtensionErmsId: "",
        agentKick: '',
        brokerage: '',
        otherBrokerage: '',
        interestBegin: '',
        isSpecification: false,
        isSpecialAgreement: false,
        isFreeXtensionErms: false,
        isExtensionWithoutDeductible: false,
        isExtensionWithoutDeductibleTwo: false,
        isExtensionWithoutDeductibleOne: false,
        //
      },
      templateData: {
        freeExtensionIllustrate: "",
        specialAgreementIllustrate: ''
      },
      companyValueCount: 0,
      rules: {
        // insuranceTerms: [
        //   {
        //     required: true,
        //     message: "请输入保险条款",
        //     trigger: ["change", "blur"],
        //   },
        // ],
        insureProgrammeName: [
          {
            required: true,
            message: "请输入承保方案名称",
            trigger: ["change", "blur"],
          },
        ],
        category: [
          {
            required: true,
            message: "请选择险种类型",
            trigger: ["change", "blur"],
          },
        ],
        vehicleTypeValue: [
          {
            required: true,
            message: "请选择设备类型",
            trigger: ["change", "blur"],
          },
        ],
        companyValue: [
          {
            required: true,
            message: "请选择保险公司",
            trigger: ["change", "blur"],
          },
        ],
        categoryValue: [
          {
            required: true,
            message: "请选择所属部门",
            trigger: ["change", "blur"],
          },
        ],
        viewDeptId: [
          {
            required: true,
            message: "请选择查看部门",
            trigger: ["change", "blur"],
          },
        ],
        interestBegin: [
          {
            required: true,
            message: "请选择起期",
            trigger: ["change", "blur"],
          },
        ],
        isAgentSideDisplay: [
          {
            required: true,
            message: "请选择是否代理人端显示",
            trigger: ["change", "blur"],
          },
        ],
        isClientDisplay: [
          {
            required: true,
            message: "请选择是否客户端显示",
            trigger: ["change", "blur"],
          },
        ],
        clientDisplayName: [
          {
            required: true,
            message: "请输入客户端显示名称",
            trigger: ["change", "blur"],
          },
        ],
        vehicleTypeId: [
          {
            required: true,
            message: "请选择小程序设备类型",
            trigger: ["change", "blur"],
          },
        ],
        attachmentResp: [
          {
            required: true,
            message: "请选择缩略图",
            trigger: ["change", "blur"],
          },
        ],
      },
      // 简单配置
      editorConfig: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              [{ align: [] }], // 对齐方式-----[{ align: [] }]
              [{ size: fontSizeStyle.whitelist }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              //   [{ font: fonts }], // 字体种类-----[{ font: [] }]
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
              [{ direction: "ltl" }], // 文本方向-----[{'direction': 'rtl'}]
              [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
              [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
              ["clean"], // 清除文本格式-----['clean']
              ["link", "image", "video"], // 链接、图片、视频-----['link', 'image', 'video']
            ],
            handlers: {
              image: this.handleImgUpload,
              video: this.handleVideoUpload,
            },
          },
        },
      },
      checkDept: false,
    };
  },
  created() {
    let routPearams = this.$route.query;
    console.log(routPearams);
    this.category = routPearams.category ? Number(routPearams.category) : "";
    this.actionType = routPearams.actionType;
    this.id = routPearams.id;
    this.init();
  },
  watch: {
    category: {
      immediate: true,
      handler(n) {
        this.formData.category = n;
        let devtype = this.devTypeMap[n];
        dictionaryBatch({ codes: `${devtype}` }).then((res) => {
          this.dict = { ...this.dict, ...res.data };
        });
      },
    },
    "formData.companyValue": {
      handler(n) {
        if (this.companyValueCount && n) {
          this.clearInsuranceData();
        } else {
          this.companyValueCount = 1;
        }
      },
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    async init() {
      let resdict = await dictionaryBatch({
        codes: `insuranceCompany,category_name`,
      });
      this.dict = { ...this.dict, ...resdict.data };
      let resdict2 = await getClientEquipmentTypeList({
        category: this.category,
      });
      this.dict.equipmentType = resdict2.data?.map((item) => {
        return {
          dictKey: item.id,
          dictValue: item.typeName,
        };
      });
      getOrganizationTree({ tenantId: this.$store.state.tenantId }).then(
        (res) => {
          this.deptOptions = res.data;
        }
      );
      if (this.actionType == "edit" || this.actionType == "view") {
        getInsuranceSchemeDeatil({ id: this.id }).then((res) => {
          res.data.vehicleTypeValue ? res.data.vehicleTypeValue = (res.data.vehicleTypeValue + '').split(',') : '';
          this.formData = res.data;
          this.detailDataHandle();
          //console.log(this.formData);
        });
      }
    },
    insuranceCompanyChange(e) {
      getInsuranceCompanyClause({
        category: this.category,
        companyCode: "insuranceCompany",
        companyValue: e,
      }).then((res) => {
        //if (res.data.length) {
        this.sortInsurance(res.data);
        //}
        let insCompany = this.dict.insuranceCompany.find((item) => {
          return item.dictKey == e;
        });
        if (insCompany) {
          this.insuranceBranchList = insCompany.remark
            .split(",")
            .map((item) => {
              return {
                dictKey: item,
                dictValue: item,
              };
            });
        } else {
          this.insuranceBranchList = [];
        }
        //this.clearInsuranceData();
      });
    },
    typesInsuranceChange(e) {
      this.category = e;
      this.insuranceCompanyChange(this.formData.companyValue);
    },
    //主险和附加险分类
    sortInsurance(data) {
      // 数据类型(0：主险、1：附加险、2：特别约定、3：免费拓展条款、4：特别说明、5:每次事故绝对免赔额)
      let insdict = {
        mainInsuranceDict: [],
        additionalInsuranceDict: [],
        specialAgreementDict: [],
        freeExtensionDict: [],
        specialInstructionsDict: [],
        absoluteDeductibleDict: [],
        //additionalInsuranceDictInnerDict: []
      };
      let sortList = [
        {
          key: "mainInsuranceDict",
          value: 0,
        },
        {
          key: "additionalInsuranceDict",
          value: 1,
        },
        {
          key: "specialAgreementDict",
          value: 2,
        },
        {
          key: "freeExtensionDict",
          value: 3,
        },
        {
          key: "specialInstructionsDict",
          value: 4,
        },
        {
          key: "absoluteDeductibleDict",
          value: 5,
        },
      ];
      sortList.forEach((so) => {
        insdict[so.key] =
          data.filter((item) => {
            return item.dataType == so.value;
          }) || [];
        if (so.value == 1 || so.value == 0) {
          insdict[so.key].forEach((insitem) => {
            let itemconformity = {};
            if (insitem.insPolicyInsuranceTypeAppendRespList.length) {
              Object.keys(
                insitem.insPolicyInsuranceTypeAppendRespList[0]
              ).forEach((key) => {
                itemconformity[key] = [];
              });
              insitem.insPolicyInsuranceTypeAppendRespList.forEach((inner) => {
                Object.keys(inner).forEach((key) => {
                  if (key == "insuranceAmount" || key == "rate") {
                    if (key == "insuranceAmount") {
                      itemconformity[key].push(
                        inner[key] + this.rateUnit[inner.unit]
                      );
                    }
                    if (key == "rate") {
                      itemconformity[key].push(
                        inner[key] + this.rateUnit[inner.rateUnit]
                      );
                    }
                  } else {
                    itemconformity[key].push(inner[key]);
                  }
                });
              });
              Object.keys(itemconformity).forEach((key) => {
                itemconformity[key] = itemconformity[key].toString();
              });
            }
            insitem.itemconformity = [itemconformity];
          });
        }
      });

      // insdict.additionalInsuranceDict.forEach(item => {
      //   item.insPolicyInsuranceTypeAppendRespList.forEach(initem => {
      //     insdict.additionalInsuranceDictInnerDict.push(initem);
      //   });
      // });
      this.dict = { ...this.dict, ...insdict };
      //console.log(4654646, this.dict, insdict);
    },
    //添加附加险
    additionalInsuranceAdd(e) {
      //console.log(e);
      this.formData.additionalInsuranceList.push({});
    },
    deletAdditionalInsurance(i) {
      this.formData.additionalInsuranceList.splice(i, 1);
    },
    selectInsurance(e, type) {
      console.log(e);
    },
    handleImgUpload(e) {
      const { quill } = this.$refs.mwQuillEditor;
      uploadFile({ accept: "image/*", fileSize: 10 }).then(async (res) => {
        let url = await OSSDirectPass({
          name: res.name,
          raw: res,
        });
        const length = quill.getSelection(true).index;
        // 获取光标所在位置
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, "image", url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      });
    },
    handleVideoUpload() {
      const { quill } = this.$refs.mwQuillEditor;
      uploadFile({ accept: "video/*", fileSize: 100 }).then(async (res) => {
        let url = await OSSDirectPass({
          name: res.name,
          raw: res,
        });
        const length = quill.getSelection(true).index;
        // 获取光标所在位置
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, "video", url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      });
    },
    selectTemplate(e, type, index) {
      console.log(e);
      if (type == "absoluteDeductibleIllustrate") {
        this.deductibleList.forEach((item) => {
          item.value = e[item.kye2];
        });
      } else if (
        type == "additionalInsuranceList" ||
        type == "mainInsuranceList"
      ) {
        //this.formData[type].splice(index, 1,e);
        this.formData[type][index].termConfigurationType =
          e.termConfigurationType;
        this.formData[type][index].termConfigurationName =
          e.termConfigurationName;
        this.formData[type][index].itemconformity = e.itemconformity;
        this.formData[type][index].insPolicyInsuranceTypeAppendRespList =
          e.insPolicyInsuranceTypeAppendRespList;
      } else {
        this.templateData[type] = e.illustrate;
      }
      //console.log(e, this.formData);
    },
    selectDeductible(e) {
      this.deductibleList.forEach((item) => {
        if (e.includes(item.key)) {
          this.formData[item.key] = true;
        } else {
          this.formData[item.key] = false;
        }
      });
    },
    tiledClause() {
      let main = [];
      let add = [];
      this.formData.mainInsuranceList?.forEach((item) => {
        let tbdata = [
          {
            calculationMethod: "浮动计算",
            insuranceType: 0,
            insuranceTypeAppendId: item.id,
            isFixedSelection: item.itemconformity?.length
              ? item.itemconformity[0].isFixedSelection || false
              : false,
            isMandatory: item.itemconformity?.length
              ? item.itemconformity[0].isMandatory || false
              : false,
          },
        ];
        main = [...main, ...tbdata];
      });
      this.formData.additionalInsuranceList?.forEach((item) => {
        let tbdata = [
          {
            calculationMethod: "浮动计算",
            insuranceType: 1,
            insuranceTypeAppendId: item.id,
            isFixedSelection: item.itemconformity?.length
              ? item.itemconformity[0].isFixedSelection || false
              : false,
            isMandatory: item.itemconformity?.length
              ? item.itemconformity[0].isMandatory || false
              : false,
          },
        ];
        add = [...add, ...tbdata];
      });
      return [...main, ...add];
    },
    detailDataHandle() {
      //e 保险公司key
      this.insuranceCompanyChange(this.formData.companyValue);
      // 将主险和附加险分开&&清空要提交的列表
      // 数据类型(0：主险、1：附加险、2：特别约定、3：免费拓展条款、4：特别说明、5:每次事故绝对免赔额)
      let insList = {
        mainInsuranceList: [],
        additionalInsuranceList: [],
      };
      let insList2 = {};
      let insList3 = {};
      let sortList = [
        {
          key: "mainInsuranceList",
          value: 0,
        },
        {
          key: "additionalInsuranceList",
          value: 1,
        },
      ];
      let sortList2 = [
        {
          key: "freeExtensionIllustrate",
          value: 3,
        },
        {
          key: "specialAgreementIllustrate",
          value: 2,
        },
        {
          key: "specificationIllustrate",
          value: 4,
        },
      ];
      //各种说明条款
      sortList2.forEach((so) => {
        insList2[so.key] =
          this.formData.insPolicyTermConfigurationRespList.filter((item) => {
            return item.dataType == so.value;
          })[0]?.illustrate || "";
      });
      this.templateData = insList2;
      //主险与附件险
      sortList.forEach((so) => {
        insList[so.key] =
          this.formData.insPolicyTermConfigurationRespList.filter((item) => {
            return item.dataType == so.value;
          });
        if (so.value == 1 || so.value == 0) {
          if (insList[so.key].length) {
            insList[so.key].forEach((insitem) => {
              let itemconformity = {};
              Object.keys(
                insitem.insPolicyInsuranceTypeAppendRespList[0]
              ).forEach((key) => {
                itemconformity[key] = [];
              });
              insitem.insPolicyInsuranceTypeAppendRespList.forEach((inner) => {
                Object.keys(inner).forEach((key) => {
                  if (key == "insuranceAmount" || key == "rate") {
                    if (key == "insuranceAmount") {
                      itemconformity[key].push(
                        inner[key] + this.rateUnit[inner.unit]
                      );
                    } else {
                      itemconformity[key].push(
                        inner[key] + this.rateUnit[inner.rateUnit]
                      );
                    }
                  } else {
                    itemconformity[key].push(inner[key]);
                  }
                });
              });
              Object.keys(itemconformity).forEach((key) => {
                itemconformity[key] = itemconformity[key].toString();
              });
              itemconformity.isMandatory = insitem.isMandatory;
              itemconformity.isFixedSelection = insitem.isFixedSelection;
              insitem.itemconformity = [itemconformity];
            });
          } else {
            insList[so.key] = [{ itemconformity: [] }];
          }
        }
      });
      // Object.keys(insList).forEach(key => {
      //   this.$set(this.formData, key, insList[key]);
      // });
      this.formData = { ...this.formData, ...insList };
      //免赔说明
      insList3 = this.formData.insPolicyTermConfigurationRespList.filter(
        (item) => {
          return item.dataType == 5;
        }
      );
      this.$set(this.formData, "deductible", []);
      if (insList3.length) {
        this.deductibleList.forEach((free) => {
          free.value = insList3[0][free.kye2] || "";
          if (this.formData[free.key]) {
            this.formData.deductible.push(free.key);
          }
        });
      }
      if (this.formData.viewDeptId) {
        let key = this.formData.viewDeptId.split(',');
        this.$refs.deptTree.setCheckedKeys(key, true);
      }
    },
    remoteMethod(e) {
      this.$refs.deptTree.filter(e);
    },
    visibleChange(v) {
      if (v) {
        this.$refs.deptTree.filter("");
      }
    },
    getDeptId(list) {
      let data = [];
      list.map(val => {
        data.push(val.id)
        if (val.children?.length) {
          data.push(...this.getDeptId(val.children));
        };
      })
      return data
    },
    changeDeptId(e,check) {
      this.checkDept ? this.checkDept = false : '';
      if (check && !this.checkDept) {
        if (e.children?.length) {
          this.checkDept = true;
          let chekeList = this.getDeptId(e.children);
          this.$nextTick(()=>{
            chekeList.map(val=>{
              this.$refs.deptTree.setChecked(val,true);
            })
          })
        }
      };
      this.formData.viewDeptId = this.$refs.deptTree
        .getCheckedNodes()
        .map((val) => val.id)
        .toString();
      this.formData.viewDeptName = this.$refs.deptTree
        .getCheckedNodes()
        .map((val) => val.title)
        .toString();
      this.$forceUpdate();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
    getDicKeyToValue(dict, key, key2, type) {
      if (type == 'arrar') {
        let res = this.dict[dict].filter((dt) => {
          return this.formData[key].includes(dt.dictKey + '');
        });
        if (res?.length) {
          this.formData[key2] = res.map(val=> val.dictValue).toString();
        } else {
          this.formData[key2] = "";
        }
        return false;
      }
      // vehicleTypeValue
      let res = this.dict[dict].find((dt) => {
        return dt.dictKey == this.formData[key];
      });
      if (res) {
        this.formData[key2] = res.dictValue;
      } else {
        this.formData[key2] = "";
      }
    },
    submit() {
      // TODO companyName 保险公司名称
      this.getDicKeyToValue(
        "equipmentType",
        "vehicleTypeId",
        "customVehicleTypeName"
      );
      this.getDicKeyToValue("insuranceCompany", "companyValue", "companyName");
      this.getDicKeyToValue(
        this.devTypeMap[this.category],
        "vehicleTypeValue",
        "vehicleTypeName",
        'arrar'
      );
      this.formData.insuranceTypeAppendsList = this.tiledClause();
      // if (!this.formData.mainInsuranceList.length && (!this.formData.additionalInsuranceList.length || !this.formData.additionalInsuranceList.termConfigurationName)) {
      //   this.$message.error('主险及附加险不能全部为空！');
      //   return false;
      // }
      if (this.$refs.configFrom.verify()) {
        const h = this.$createElement;
        this.$msgbox({
          title: "编辑",
          type: "warning",
          message: h("p", null, [
            h("span", null, "当前方案缺少 "),
            h(
              "span",
              { style: "color: #C94242FF" },
              this.$refs.configFrom.verify()
            ),
            h("span", null, "的条款，是否继续？"),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then((action) => {
          if (action == "confirm") {
            this.$refs.formData.validate((valid) => {
              if (valid) {
                for (let index = 0; index < this.deductibleList.length; index++) {
                  if (this.deductibleList[index].key == 'isExtensionWithoutDeductibleOne') {
                    this.formData.extensionWithoutOne = this.deductibleList[index].value;
                  }else if (this.deductibleList[index].key == 'isExtensionWithoutDeductibleTwo') {
                    this.formData.extensionWithoutTwo = this.deductibleList[index].value;
                  }else {
                    this.formData.extensionWithout = this.deductibleList[index].value;
                  }
                }
                this.formData.freeIllustrate = this.templateData.freeExtensionIllustrate;
                this.formData.specialIllustrate = this.templateData.specialAgreementIllustrate;
                addInsuranceScheme({...this.formData, vehicleTypeValue: this.formData.vehicleTypeValue ? this.formData.vehicleTypeValue.toString() : ''}).then((res) => {
                  this.backToHome();
                });
              }
            });
          }
        });
      } else {
        this.$refs.formData.validate((valid) => {
          if (valid) {
            for (let index = 0; index < this.deductibleList.length; index++) {
              if (this.deductibleList[index].key == 'isExtensionWithoutDeductibleOne') {
                this.formData.extensionWithoutOne = this.deductibleList[index].value;
                }else if (this.deductibleList[index].key == 'isExtensionWithoutDeductibleTwo') {
                  this.formData.extensionWithoutTwo = this.deductibleList[index].value;
                  }else {
                     this.formData.extensionWithout = this.deductibleList[index].value;
                     }
                     }
            this.formData.freeIllustrate = this.templateData.freeExtensionIllustrate;
            this.formData.specialIllustrate = this.templateData.specialAgreementIllustrate;
            addInsuranceScheme({...this.formData, vehicleTypeValue: this.formData.vehicleTypeValue ? this.formData.vehicleTypeValue.toString() : ''}).then((res) => {
              this.backToHome();
            });
          }
        });
      }
    },
    clearInsuranceData() {
      this.formData.branch = "";
      this.formData.specificationId = "";
      this.formData.specialAgreementId = "";
      this.formData.freeXtensionErmsId = "";
      this.formData.freeXtensionErmsId = "";
      this.formData.isSpecification = false;
      this.formData.isSpecialAgreement = false;
      this.formData.isFreeXtensionErms = false;
      this.templateData = {};
      this.formData.deductible = [];
      this.deductibleList.forEach((item) => {
        this.formData[item.key] = false;
      });
      this.deductibleList.forEach((item) => {
        item.value = "";
      });
      this.formData["additionalInsuranceList"] = [
        {
          insuranceTypeAppendId: "",
          insPolicyInsuranceTypeAppendRespList: [{ itemconformity: [] }],
        },
      ];
      this.formData["mainInsuranceList"] = [
        {
          insuranceTypeAppendId: "",
          insPolicyInsuranceTypeAppendRespList: [{ itemconformity: [] }],
        },
      ];
    },
    backToHome() {
      this.$router.replace({
        path: "/routerPolicyManagement/insuredManagement/InsuredSchemeManage",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-select {
  width: 100%;
}
.el-select-dropdown__item {
  height: auto !important;
}
.header-row {
  background-color: #ebf3ffff;
}
.main {
  height: 100%;
}
.base-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0 84px;
  > div:last-child {
    grid-column: 1 / -1;
  }
}
.bottom-btn {
  z-index: 99;
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 8px 8px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn {
    margin-right: 24px;
    .text {
      font-size: 14px;
      font-weight: 400;
    }
    .save {
      background-color: #4278c9ff;

      color: #ffffff;
    }
  }
}
</style>
<style lang="scss">
a[href="https://froala.com/wysiwyg-editor"], a[href="https://www.froala.com/wysiwyg-editor?k=u"]
{
  display: none !important;
  position: absolute;
  top: -99999999px;
}
.vue-quill-editor {
  .quill-editor {
    line-height: normal;
    .ql-container.ql-snow {
      line-height: normal !important;
      height: 550px !important;
      font-size: 14px;
    }
    .ql-snow {
      max-height: 360px !important;
      .ql-tooltip[data-mode="link"]::before {
        content: "请输入链接地址:";
      }
      .ql-tooltip.ql-editing a.ql-action::after {
        border-right: 0px;
        content: "保存";
        padding-right: 0px;
      }
      .ql-tooltip[data-mode="video"]::before {
        content: "请输入视频地址:";
      }
      .ql-picker.ql-size {
        .ql-picker-label[data-value="12px"]::before,
        .ql-picker-item[data-value="12px"]::before {
          content: "12px";
        }
        .ql-picker-label[data-value="14px"]::before,
        .ql-picker-item[data-value="14px"]::before {
          content: "14px";
        }
        .ql-picker-label[data-value="16px"]::before,
        .ql-picker-item[data-value="16px"]::before {
          content: "16px";
        }
        .ql-picker-label[data-value="18px"]::before,
        .ql-picker-item[data-value="18px"]::before {
          content: "18px";
        }
        .ql-picker-label[data-value="20px"]::before,
        .ql-picker-item[data-value="20px"]::before {
          content: "20px";
        }
        .ql-picker-label[data-value="24px"]::before,
        .ql-picker-item[data-value="24px"]::before {
          content: "24px";
        }
        .ql-picker-label[data-value="28px"]::before,
        .ql-picker-item[data-value="28px"]::before {
          content: "28px";
        }
        .ql-picker-label[data-value="32px"]::before,
        .ql-picker-item[data-value="32px"]::before {
          content: "32px";
        }
        .ql-picker-label[data-value="36px"]::before,
        .ql-picker-item[data-value="36px"]::before {
          content: "36px";
        }
      }
      .ql-picker.ql-header {
        .ql-picker-label::before,
        .ql-picker-item::before {
          content: "文本";
        }
        .ql-picker-label[data-value="1"]::before,
        .ql-picker-item[data-value="1"]::before {
          content: "标题1";
        }
        .ql-picker-label[data-value="2"]::before,
        .ql-picker-item[data-value="2"]::before {
          content: "标题2";
        }
        .ql-picker-label[data-value="3"]::before,
        .ql-picker-item[data-value="3"]::before {
          content: "标题3";
        }
        .ql-picker-label[data-value="4"]::before,
        .ql-picker-item[data-value="4"]::before {
          content: "标题4";
        }
        .ql-picker-label[data-value="5"]::before,
        .ql-picker-item[data-value="5"]::before {
          content: "标题5";
        }
        .ql-picker-label[data-value="6"]::before,
        .ql-picker-item[data-value="6"]::before {
          content: "标题6";
        }
      }
      .ql-picker.ql-font {
        .ql-picker-label[data-value="SimSun"]::before,
        .ql-picker-item[data-value="SimSun"]::before {
          content: "宋体";
          font-family: "SimSun" !important;
        }
        .ql-picker-label[data-value="SimHei"]::before,
        .ql-picker-item[data-value="SimHei"]::before {
          content: "黑体";
          font-family: "SimHei";
        }
        .ql-picker-label[data-value="Microsoft-YaHei"]::before,
        .ql-picker-item[data-value="Microsoft-YaHei"]::before {
          content: "微软雅黑";
          font-family: "Microsoft YaHei";
        }
        .ql-picker-label[data-value="KaiTi"]::before,
        .ql-picker-item[data-value="KaiTi"]::before {
          content: "楷体";
          font-family: "KaiTi" !important;
        }
        .ql-picker-label[data-value="FangSong"]::before,
        .ql-picker-item[data-value="FangSong"]::before {
          content: "仿宋";
          font-family: "FangSong";
        }
      }
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-align-right {
      text-align: right;
    }
    .ql-align-left {
      text-align: left;
    }
  }
}
</style>
